@import '../../variables.scss';
.partner-logo-container {
  width: calc(25% - 20px);
  background-color: $color-white-gray;
  padding-top: calc(25% - 20px);
  position: relative;
  border-radius: 6px;
  margin-bottom: 27px;
  @media screen and (max-width: 768px) {
    width: calc(50% - 8px);
    padding-top: 40%;
    margin-bottom: 16px;
    padding-top: calc(50% - 8px);
  }

  .partner-logo {
    position: absolute;
    display: block;
    top: 50%;
    left: 12%;
    right: 12%;
    transform: translateY(-50%);
  }
}

.book-car-container {
  padding-top: 28%;

  @media screen and (max-width: 768px) {
    padding-top: 340px;
  }
  .partners-title {
    bottom: 60px;
    left: 10%;
    right: 10%;

    @media screen and (max-width: 1200px) {
      left: 5%;
      right: 5%;
    }
    @media screen and (max-width: 768px) {
      left: 15px;
      right: 15px;
      bottom: 40px;
    }
  }
}
.partner-logos-container {
  
  margin: 84px 0;
  @media screen and (max-width: 768px) {
    margin: 24px 0px 84px;
  }
}
.cookie-notice {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  .accept-cookies-button {
    background-color: #149a59;
    color: #ffffff;

    &:hover {
      color: #ffffff;
    }
  }

  @media screen and (min-width: 768px) {
    .cookies-button-container {
      max-width: 400px;
      min-width: 340px;
    }
  }
}

@keyframes slide-top {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0px);
  }
}

@import '../../../../variables.scss';

.booking-widget-location-inputs-container {
  gap: 10px;
}
.booking-widget-location-input-container {
  .p-inputgroup-addon {
    background-color: $input-bg-color;
    border: none;
  }

  .p-component {
    opacity: 1;
    border: none;
    border-radius: 4px;
    // padding-left: 0px;
  }
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #6e6e6e;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #6e6e6e;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #6e6e6e;
  }
}

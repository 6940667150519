.mobile-menu {
  z-index: 50;
}
.menu-button {
  z-index: 100;
}
.lang-selection {
  .p-accordion-header-link {
    background-color: transparent !important;
    border: none !important;
    font-weight: 400 !important;
    padding: 0 !important;
  }
  .p-accordion-toggle-icon {
    order: 2;    
    margin-left: 5px;
    font-size: 0.8rem;
    margin-top: 5px;
    &.pi-chevron-right:before {
      content: '\e902';
    }
  }
}
.lang-options {
  .p-accordion-content {
    border: none !important;
    padding: 0 !important;
    margin-left: 10px;

  }
}

@import '../../variables.scss';
.home {
  font-family: $font-family;
  .help-center-icon {
    position: fixed;
    bottom: 30px;
    right: 90px;
    width: 50px;
    height: 50px;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
  }
  .home-title {
    bottom: 106px;
    max-width: 1536px;
    width: calc(80% - 10px);
    transform: translate(-50%, 0%);
    left: 50%;
    h1 {
      font-size: 50px !important;
    }
    @media screen and (max-width: 1200px) {
      left: calc(5% + 10px);
      right: calc(5% + 10px);
      transform: none;
      width: calc(90% - 20px);
    }
    @media screen and (max-width: 1140px) {
      left: calc(5% + 10px);
      right: calc(5% + 10px);
      bottom: 115px;
    }
    @media screen and (max-width: 768px) {
      bottom: auto;
      top: 40px;

      h1 {
        font-size: 40px !important;
      }
    }
    .sbb-logo {
      max-width: 140px;
    }
  }
  .overlay {
    &.open {
      top: -$home-header-height;
    }
  }
  .widget-container {
    position: absolute;
    max-width: $max-width-container;
    width: 80%;
    transform: translate(-50%, -50%);
    height: auto;
    padding: 26px 40px 50px !important;

    -webkit-box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.15);
    box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.15);

    @media screen and (max-width: 1150px) {
      padding: 0 1.5rem 1rem !important;
    }

    @media screen and (max-width: 1200px) {
      width: 90%;
    }
    @media screen and (max-width: 768px) {
      transform: translate(-50%, 0%);
      padding: 0rem 1.5rem 1rem !important;
      top: calc(50% + 18px);
    }
    &.open {
      height: calc(100vh - $home-header-height);
      max-width: inherit;
    }
    .booking-widget-vertical-divider {
      width: 0;
    }
    .p-inputtext {
      background: $color-white-gray;
      opacity: 1;
    }
    .p-checkbox .p-checkbox-box,
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
      background: $color-white-gray;
    }
    .p-inputgroup-addon {
      background: $color-white-gray;
    }
    .booking-widget-date-time-input {
      background: $color-white-gray;
      border-radius: 4px;
    }
  }

  .home-widget-container {
    top: 20%;
  }
  .value-propostion-title {
    font-size: 36px !important;
    @media screen and(max-width: 768px) {
      font-size: 26px !important;
    }
  }
  .value-propostion-text {
    line-height: 28px;
    padding-bottom: 40px;
    max-width: 450px;

    @media screen and(max-width: 768px) {
      line-height: 24px;
    }
  }
  .logos {
    margin-right: 20px;

    @media screen and(max-width: 768px) {
      margin-right: 15px;
      width: auto !important;
      img {
        height: 40px;
        width: auto;
      }
    }
  }
  .book-car-container {
    // padding-top: 28%;
    padding-top: 340px;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #0000004d;
    }

    @media screen and (max-width: 768px) {
      padding-top: 430px;
    }

    .book-car {
      width: 80%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      // transform: translate(-50%, -50%);
      // top: 54%;

      &.open {
        top: $home-header-height;
        @media screen and (max-width: 992px) {
          top: $header-height-mobile;
        }
      }

      @media screen and (max-width: 768px) {
        left: 15px;
        width: calc(100% - 30px);
        transform: none;
        top: 50px;
        height: auto;
        -webkit-box-shadow: 0px 0px 12px -3px $text-color-muted;
        box-shadow: 0px 0px 12px -3px $text-color-muted;
      }
    }
  }
  .app-info-container {
    @media screen and (max-width: 1140px) {
      padding-top: 8rem !important;
    }
    @media screen and (max-width: 768px) {
      padding-top: 7rem !important;
    }
    .app-info {
      padding: 20px 0 20px 10%;
      
      @media screen and (max-width: 768px) {
        padding: 50px 16px 40px;
      }
    }
    @media screen and (min-width: 1921px) {
      max-width: $max-width-container;
      margin: 0 auto !important;
      .app-info {
        padding: 0;
      }
    }
  }
  .app-video-container {
    margin-bottom: -120px;
    .video-text {
      max-width: 300px;
    }
    .app-video-container-video {
      max-width: 640px;
    }
    @media screen and (max-width: 768px) {
      padding: 18px 0px !important;
      margin-bottom: 0;
    }
    .app-video-text-container {
      @media screen and (max-width: 1000px) {
        margin-top: -30px;
      }
      @media screen and (max-width: 850px) {
        margin-top: -40px;
      }
      @media screen and (max-width: 768px) {
        margin-top: 30px;
        padding: 0 15px;
      }
      .app-video-text-container-title {
        font-size: 24px;

        @media screen and (max-width: 768px) {
          font-size: 20px;
        }
      }
    }
  }
  .how-it-works-container {
    background-color: $color-white-gray;
    padding-top: 130px;
    @media screen and (max-width: 768px) {
      padding-top: 60px;
    }
    .how-it-works-title {
      font-size: 24px;

        @media screen and (max-width: 768px) {
          font-size: 20px;
        }
    }
    .p-tabview .p-tabview-panels {
      background-color: transparent;

      @media screen and (max-width: 768px) {
        padding: 0;
      }
      .cards-container {
        .card-container {
          box-shadow: 14px 14px 30px rgba(0, 0, 0, 0.2);
          &.repeat {
            width: calc(25% - 18px);
            @media screen and (max-width: 768px) {
              width: 100%;
            }
          }
          padding: 30px 20px;
          &:last-child {
            margin: 0 !important;
          }
          .card-icon {
            width: 44px;
          }
        }
      }
    }
    .p-tabview .p-tabview-nav {
      width: 100%;
      justify-content: center;
      margin-bottom: 40px;

      @media screen and (max-width: 768px) {
        margin-bottom: 30px;
      }
      .p-tabview-nav-link {
        padding: 5px 0px 10px;
        background-color: transparent;
        color: #6e6e6e;
        &:not(:first-child) {
          padding-left: 20px;
        }
      }
      li {
        &:not(:first-child) {
          .p-tabview-nav-link {
            margin-left: 20px;
          }
        }
      }
      .p-highlight {
        .p-tabview-nav-link {
          color: #00a664;
        }
      }
      li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
        background: transparent;
      }
    }
  }

  .faq-container {
    @media screen and(max-width: 768px) {
      border-bottom: 1px #bcbcbc80 solid;
      padding-bottom: 50px;
    }
    .faq-container-title {
      font-size: 36px;
      @media screen and(max-width: 768px) {
        font-size: 26px;
      }
    }
    .faq-container-text {
    }
    .p-accordion {
      width: calc(50% - 22px);
      @media screen and(max-width: 768px) {
        width: 100%;
      }

      .p-accordion-header {
        &:hover {
          .p-accordion-header-link {
            background: transparent !important;
          }
        }

        &:not(.p-disabled).p-highlight .p-accordion-header-link {
          background-color: transparent;
        }
        .p-accordion-header-link {
          flex-direction: row-reverse;
          justify-content: space-between;
          background: transparent;
          border: none;
          padding: 1rem 0;
          font-weight: 500;
          font-size: 16px !important;
          .p-accordion-header-text {
            margin-right: 8px;
          }
          .p-accordion-toggle-icon {
            background: $color-white-gray;
            width: 32px;
            height: 32px;
            min-width: 32px;
            border-radius: 50%;
            position: relative;
            &:before {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              font-size: 12px;
              content: '\e903';
            }
          }
        }
        &:not(.p-highlight) {
          .p-accordion-toggle-icon {
            &:before {
              content: '\e902';
            }
          }
        }
      }
      .p-accordion-tab {
        border-bottom: 1px #bcbcbc80 solid;
        width: 100%;
        &.p-accordion-tab-active {
          .p-accordion-header-link {
            padding-top: 20px;
          }
        }
        &:last-child {
          border-bottom: none;
        }
        .p-accordion-content {
          border: none;
          padding: 0 40px 0 0;
          font-size: 12px;
          line-height: 24px;
          margin-bottom: 20px;
          p {
            margin: 0;
          }
        }
      }
    }
  }
  .partners-conatiner {
    margin-bottom: 100px;
    margin-top: 100px;
    .p-button.p-button-secondary.p-button-outlined {
      color: #130c0e;
      font-size: 14px;
      margin-top: 37px;
      padding: 14px 20px !important;
    }
    .partners-title {
      font-size: 36px !important;
      @media screen and (max-width: 768px) {
        font-size: 26px !important;
      }
    }
  }
  .how-it-works-container {
    background-color: #f1f1f1;
    padding-top: 130px;
    @media screen and (max-width: 768px) {
      padding-top: 60px;
    }
    .p-tabview .p-tabview-panels {
      background-color: transparent;

      @media screen and (max-width: 768px) {
        padding: 0;
      }
      .cards-container {
        .card-container {
          box-shadow: 14px 14px 30px rgba(0, 0, 0, 0.2);
          &.repeat {
            width: calc(25% - 18px);
            @media screen and (max-width: 768px) {
              width: 100%;
            }
          }
          padding: 30px 20px;
          &:last-child {
            margin: 0 !important;
          }
          .card-icon {
            width: 44px;
          }
        }
      }
    }
    .p-tabview .p-tabview-nav {
      width: 100%;
      justify-content: center;
      margin-bottom: 40px;

      @media screen and (max-width: 768px) {
        margin-bottom: 30px;
      }
      .p-tabview-nav-link {
        padding: 5px 0px 10px;
        background-color: transparent;
        &:not(:first-child) {
          padding-left: 20px;
        }
      }
      li {
        &:not(:first-child) {
          .p-tabview-nav-link {
            margin-left: 20px;
          }
        }
      }
      .p-highlight {
        .p-tabview-nav-link {
          color: #130c0e;
          border-color: #00a664;
        }
      }
      li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
        background: transparent;
      }
    }
  }

  .faq-container {
    @media screen and(max-width: 768px) {
      border-bottom: 1px #bcbcbc80 solid;
      padding-bottom: 50px;
    }
    .p-accordion {
      width: calc(50% - 22px);
      @media screen and(max-width: 768px) {
        width: 100%;
      }

      .p-accordion-header {
        &:hover {
          .p-accordion-header-link {
            background: transparent !important;
          }
        }

        &:not(.p-disabled).p-highlight .p-accordion-header-link {
          background-color: transparent;
        }
        .p-accordion-header-link {
          flex-direction: row-reverse;
          justify-content: space-between;
          background: transparent;
          border: none;
          padding: 1rem 0;
          .p-accordion-toggle-icon {
            background: #f1f1f1;
            width: 32px;
            height: 32px;
            min-width: 32px;
            border-radius: 50%;
            position: relative;
            &:before {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              font-size: 12px;
              content: '\e903';
            }
          }
        }
        &:not(.p-highlight) {
          .p-accordion-toggle-icon {
            &:before {
              content: '\e902';
            }
          }
        }
      }
      .p-accordion-tab {
        border-bottom: 1px #bcbcbc80 solid;
        width: 100%;
        &:last-child {
          border-bottom: none;
        }
        .p-accordion-content {
          border: none;

          padding: 0 40px 0 0;
          font-size: 12px;
          line-height: 24px;
        }
      }
    }
  }
  .partners-conatiner {
    .p-button.p-button-secondary.p-button-outlined {
      color: #130c0e;
    }
  }
  .partner-logo {
    width: calc(23.33% - 50px);

    @media screen and (max-width: 768px) {
      width: 70%;
      margin: 0 auto;
    }
  }
  .p-carousel .p-carousel-content .p-carousel-prev,
  .p-carousel .p-carousel-content .p-carousel-next {
    display: none;
  }
  .media-images-gray {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    filter: grayscale(100%);
    &:hover {
      filter: none;
    }
  }
}

@import '../../../../variables.scss';

.booking-widget-date-time-input {
  .line {
    width: 1px;
    background: #6E6E6E;
    margin: 5px 0;
  }
  .p-component {
    opacity: 1;
    border: none;
    border-radius: 4px;
    color: $text-color;
    min-width: 7rem;
    @media screen and (max-width: 768px) {
      min-width: 4rem;
    }
    @media screen and (max-width: 400px) {
      min-width: 3rem !important;
      padding: 0.5rem 0.3rem !important;
    }
  }
  
      ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #6e6e6e;
        opacity: 1; /* Firefox */
      }
  
      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #6e6e6e;
      }
  
      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #6e6e6e;
      }
  .time-input {
    background-color: $input-bg-color;
    border: none;
    // color: $text-color-dark-grey;
    min-width: 4.4rem;

    // &::after {
    //   content: '';
    //   border-left: 1px solid $text-color-dark-grey;
    //   width: 1px;
    //   position: absolute;
    //   height: 60%;
    //   left: 0;
    //   top: 0;
    // }
  }
}

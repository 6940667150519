.p-dialog {
  &.location-overview-dialog {
    // width: 60vh;
    // height: 100vh;

    @media screen and (min-width: 576px) {
      width: 85vw !important;
    }

    @media screen and (min-width: 992px) {
      width: 50vw !important;
    }
  }
}

@import './variables.scss';
.App {
  &.open, &.loader-open, &.open-legal {
    // overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
    // height: calc(100vh - $home-header-height);
    // @media screen and (max-width: 960px) {
    //   height: calc(100vh - $header-height-mobile);
    // }
    .header {
      position: fixed !important;
    }
  }
}

@import '../../../variables.scss';

.p-dialog {
  .mobile-widget-input-header {
    &.p-dialog-header {
      background-color: $primary-color;
      color: $primary-color-text;

      .p-dialog-header-close-icon {
        color: $primary-color-text;
        font-size: 1.5rem;
      }
    }
  }
}

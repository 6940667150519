@import '../../../variables.scss';
.p-progress-spinner {
  z-index: 1000;
  background-color: #c5c5c5b3;
  
  .p-progress-spinner-svg {
    width: 100px;
  }
  .p-progress-spinner-circle {
    stroke: $primary-color !important;
  }
}

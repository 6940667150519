// @import '../node_modules/primereact/resources/themes/bootstrap4-light-blue/theme.css';
@import '../node_modules/primereact/resources/primereact.min.css'; //core css
@import '../node_modules/primeicons/primeicons.css';
@import '../node_modules/primeflex/primeflex.scss';
@import './theme.scss';
@import './variables.scss';

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $font-family;
}

.container {
  padding: 23px 10% !important;

    @media screen and (min-width: 1921px) {
      max-width: $max-width-container;
      padding-left: 0 !important;
      padding-right: 0 !important;
      margin: 0 auto !important ; 
  }

  @media screen and (max-width: 1200px) {
    padding: 20px 5% !important;
  }
  @media screen and (max-width: 992px) {
    padding: 18px 16px !important;
  }
}

.tabs-container {
  .p-tabview .p-tabview-panels {
    padding: 0;
  }
  .p-tabview-nav-content {
    // padding: 0 calc(10% - 1.25rem);
    margin-bottom: 1rem;
    // -webkit-box-shadow: 0px 2px 3px 0px #00000029;
    // box-shadow: 0px 2px 3px 0px #00000029;
  }
}
.word-break-small {
  @media screen and (max-width: 768px) {
    word-break: break-word;
  }
}

@import '../../../variables.scss';
.legals-container {
  max-width: 70%;
  max-height: 80vh !important;
  overflow-y: hidden;
  @media screen and (max-width: 768px) {
    max-width: 100%;
    max-height: 100vh !important;
  }
  .scroll-container {
    direction: rtl;
    max-height: calc(80vh - 64px) !important;
    @media screen and (max-width: 768px) {
      max-height: calc(100vh - 64px) !important;
    }
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 5px;
      background-color: $text-color-white-smoke;
      left: -100px;
    }

    &::-webkit-scrollbar-track {
      margin-right: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $primary-color;
    }
  }
  .content {
    direction: ltr;
  }
}

@import '../../../variables.scss';

.footer-background-container {
  background-color: #14884f;
  font-family: $font-family;
  padding-bottom: 20px;

  @media screen and (max-width: 992px) {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .footer-container {
    @media screen and (min-width: 1921px) {
      max-width: $max-width-container;
      margin: 0 auto !important ;
    }
  }
  .lang-selection {
    .p-accordion-header-link {
      background-color: transparent !important;
      border: none !important;
      font-weight: 400 !important;
      padding: 0 !important;
      color: $primary-color-text !important;
    }
    .p-accordion-toggle-icon {
      order: 2;
      margin-left: 5px;
      font-size: 0.8rem;
      margin-top: 5px;
      color: $primary-color-text !important;
      &.pi-chevron-right:before {
        content: '\e902';
      }
    }
  }
  .lang-options {
    .p-accordion-content {
      border: none !important;
      padding: 0 !important;
      background: transparent;
    }
  }
  .footer-section-column {
    @media screen and (max-width: 992px) {
      width: calc(50% - 13px);
    }
  }
  .footer-store-logos {
    @media screen and (max-width: 992px) {
      gap: 15px;
    }
  }
  .footer-logo {
    width: 174px;

    @media screen and (max-width: 992px) {
      width: 150px;
    }
  }
}

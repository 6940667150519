@import '../../../../variables.scss';

@mixin disabled-calendar-nav-button {
  background-color: $color-grey-90 !important;
  color: $text-color-light-gray !important;
}

.date-picker-widget {
  .booking-calendar {
    .p-datepicker-group-container {
      gap: 2rem;

      .p-datepicker-header {
        justify-content: center;

        .p-datepicker-title {
          margin: 0px;

          .p-datepicker-year {
            display: none;
          }
        }
      }

      .p-datepicker-calendar {
        tr {
          span {
            font-size: 0.75rem;
            font-weight: 400;
          }
        }
        td {
          padding: 0.3rem;
          span {
            background-color: $text-color-white-smoke;
            margin-top: 0.3rem;
            margin-bottom: 0.3rem;
            font-size: 0.875rem;
            font-weight: 600;

            &:not(.p-highlight):not(.p-disabled):hover,
            &.p-highlight {
              background-color: $primary-color !important;
              color: $primary-color-text !important;
            }

            &.p-disabled {
              background-color: $color-grey-90;
              color: $text-color-light-gray;
            }
          }
        }
      }

      @media only screen and (min-width: 769px) {
        .p-datepicker-header {
          justify-content: center;

          .p-datepicker-next,
          .p-datepicker-prev {
            background: $primary-color;
            color: $primary-color-text;
          }

          .p-datepicker-month {
            text-transform: uppercase;
          }

          .p-datepicker-prev-icon,
          .p-datepicker-next-icon {
            font-size: 0.75rem;
          }

          .p-datepicker-title {
            margin: 0 auto;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 769px) {
  .date-picker-widget {
    &.disabled-back-button {
      .p-datepicker-prev {
        background-color: $color-grey-90 !important;
        color: $text-color-light-gray !important;
        @include disabled-calendar-nav-button;
      }
    }

    &.disabled-next-button {
      .p-datepicker-next {
        @include disabled-calendar-nav-button;
      }
    }
  }
}

// Variables
$primary-color: #179a5a;
$primary-color-alpha-50: #179a5a7f;
$primary-color-alpha-35: #179a5a59;

$green: #0fbd78;
$dark-green: #179a5a;
$middle-green: #00A664;

$text-color: #000000;
$primary-color-text: #ffffff;
$text-color-muted: #707070;
// $text-danger: #ea5248;

$font-family: 'Raleway', sans-serif;

$input-bg-color: #fafafa;

$text-color-dark-grey: #b1b1b1;
$text-color-light-gray: #c5c5c5;
$text-color-white-smoke: #f5f5f5;
$color-white-gray: #F1F1F1;
$color-grey-90: #e5e5e5;
$color-black-90: #130C0E;
$home-header-height: 90px;
$booking-header-height: 90px;
$header-height-mobile: 70px;
$max-width-container: 1536px;
$footer-height: 344px;

// Mixins
@mixin header-height($height) {
  max-height: $height;
  height: $height;

  @media screen and (max-width: 992px) {
    max-height: $height;
    height: $height;
  }
}

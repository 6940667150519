@import '../../../../variables.scss';

$time-item-height: 1.875rem;

.time-select-container {
  scroll-snap-type: y proximity;
  scroll-snap-type: proximity;
  // scroll-snap-points-y: repeat(300px);
  scroll-snap-type: y proximity;
  &.scroll-panel {
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .time-item {
    direction: ltr;
    // padding-left: 10px;
    cursor: pointer;
    scroll-snap-align: center;
    // height: $time-item-height;
    // color: $text-color-dark-grey;

    p {
      &.active {
        font-size: 1.25rem !important;
        color: $text-color;
        // background-color: $primary-color-alpha-35;
        // color: $primary-color-text;
      }
    }

    // &:hover {
    //   background-color: $primary-color-alpha-35;
    //   color: $primary-color-text;
    // }
  }

  @media only screen and (min-width: 769px) {
    scroll-snap-type: none;

    .time-item {
      scroll-snap-align: initial;
      p {
        &.active,
        &:hover {
          background-color: $primary-color-alpha-35;
          color: $primary-color-text;
          font-size: 1rem !important;
        }
      }
    }

    &.scroll-panel {
      &::-webkit-scrollbar {
        display: block;
      }
    }
  }
}

.mobile-time-selection-overlay {
  // height: $time-item-height;
  background-color: $input-bg-color;
  top: 46%;
}

.p-carousel-prev,
.p-carousel-next {
  display: none;
}

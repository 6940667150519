@import '../../../../variables.scss';

.widget-locations-container {
  .no-results-found {
    color: $text-color-dark-grey;
  }

  section {
    direction: ltr;

    .location-item {
      &:hover {
        background-color: $primary-color-alpha-35;
        color: $text-color;
      }
    }
  }

  .booking-location-icon {
    width: 18px;
    height: 18px;
  }
}

.scroll-panel {
  direction: rtl;

  .location-section {
    &:last-child {
      margin-right: auto;
    }
  }

  @media screen and (min-width: 768px) {
    max-height: 50vh;
    margin-left: -30px;
    padding-left: 25px;
  }

  &::-webkit-scrollbar {
    width: 5px;
    background-color: $text-color-white-smoke;
    left: -100px;
  }

  &::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    margin-right: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primary-color;
    // outline: 1px solid slategrey;
  }
}

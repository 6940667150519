.inputs-container {
  @media screen and (min-width: 768px) and (max-width: 1140px) {
    flex-wrap: wrap;
    .p-divider {
      display: none !important;
    }
    .location-inputs-container {
      width: 100%;
      max-width: 100% !important;
    }

    .homepage-submit {
      align-self: flex-end;
    }
  }
  @media screen and (max-width: 768px) {
    .homepage-submit {
      height: 3rem !important;
      font-size: 0.875rem !important;
    }
  }
}

@import '../../../variables.scss';

.header {
  max-height: $home-header-height;
  height: $home-header-height;    
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
  @media screen and (max-width: 992px) {
    max-height: $header-height-mobile;
    height: $header-height-mobile;
    .container {
      padding: 12px 16px !important;
    }
  }
  &.stickyHeader {
    position: fixed;
  }
  .p-menubar {
    .p-menubar-end {
      margin-left: 10px;
    }
    .p-menubar-start {
      width: 213px;
      height: 34px;
    }
    .p-menubar-root-list {
      margin-left: auto;
      .p-menuitem {
        font-size: 14px;
        .p-menuitem-link {
          &:focus {
            box-shadow: none;
          }
          .p-menuitem-text {
            color: $text-color;
          }
        }
      }
    }

    @media screen and (max-width: 992px) {
      &.p-menubar-mobile-active {
        .p-menubar-start {
          display: block;
          order: 2;
          padding-left: 5px;
        }
        .p-menubar-end {
          display: none;
        }
        .p-menubar-button {
          .pi-bars:before {
            content: '\e90b' !important;
          }
        }
      }
      .p-menubar-button {
        -webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        user-select: none;
        &:focus {
          box-shadow: none;
        }
      }
      .p-menubar-start {
        display: none;
      }
      .p-menubar-end {
        margin-left: auto;
      }
      .p-menubar-root-list {
        padding: 30px 0 0 55px;
        border: 0;
        height: calc(100vh - $header-height-mobile);
        .p-menuitem {
          font-size: 13px;
          .p-menuitem-link {
            padding-left: 0;
            padding-bottom: 8px;
            .p-submenu-icon {
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
}

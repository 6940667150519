@import '../../../variables.scss';

@mixin gradient-button {
  background: linear-gradient(45deg, $green 0%, $dark-green 100%);
}

.primary-button.p-button {
  @include gradient-button;

  &.solid-green-background {
    background: #14884F;
    padding: 15px 20px;
    &:enabled:hover {
      background: #14884F;
    }
  }
  &:enabled:hover {
    @include gradient-button;
  }
}

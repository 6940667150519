@import '../../variables.scss';
.book-car-container {
  padding-top: 340px;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #0000004d;
  }

  @media screen and (max-width: 768px) {
    padding-top: 340px;
  }
  .partners-title {
    bottom: 60px;
    left: 10%;
    right: 10%;

    @media screen and (max-width: 1200px) {
      left: 5%;
      right: 5%;
    }
    @media screen and (max-width: 768px) {
      left: 15px;
      right: 15px;
      bottom: 40px;
    }
    h1 {
      font-size: 50px !important;
      @media screen and (max-width: 768px) {
        font-size: 40px !important;
      }
    }
  }
}

.media-container {
  padding: 100px 17% !important;
  @media screen and (max-width: 992px) {
    padding: 40px 16px 100px !important;
  }
  .p-divider.p-divider-horizontal {
    margin: 26px 0 30px 0;
  }
  .p-divider.p-divider-horizontal:before {
    height: 1px;
    background-color: #bcbcbc;
    opacity: 0.5;
  }
  .media-button-link {
    top: 10px;
    right: 10px;
    background-color: $color-white-gray;
    width: 44px;
    height: 44px;
    @media screen and(max-width:768px) {
      width: 32px;
      height: 32px;
      i {
        font-size: 12px;
      }
    }
    i {
      transform: translate(-50%, -50%);
    }
  }
}

@import '../../../../variables.scss';
.booking-widget-vertical-divider,
.booking-widget-horizontal-divider {
  background-color: $text-color-dark-grey;
}

.booking-widget-vertical-divider {
  width: 1px;
}

.pickup-input {
  @media screen and (min-width: 768px) and (max-width: 1140px) {
    margin-right: 1rem;
  }
}

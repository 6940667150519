@import '../../../variables.scss';
.layout {
  margin-top: $home-header-height;
  @media screen and (max-width: 992px) {
    margin-top: $header-height-mobile;
  }
  .layout-container {
    min-height: calc(100vh - $footer-height - $home-header-height);
  }
}

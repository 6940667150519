.app-video-container {
  .full-carousel-container {
    max-width: 640px;
    @media screen and (max-width: 768px) {
      height: 420px;
    }
    @media screen and (max-width: 550px) {
      height: 380px;
    }
    @media screen and (max-width: 500px) {
      height: 360px;
    }
    @media screen and (max-width: 450px) {
      height: 340px;
    }
    @media screen and (max-width: 400px) {
      height: 325px;
    }
    @media screen and (max-width: 350px) {
      height: 290px;
    }
  }
  .video-player-container {
    width: 100%;
    position: relative;
    padding-bottom: 56.25%;
    @media screen and (max-width: 768px) {
      padding-bottom: 0;
    }
    :first-child {
      overflow: hidden;
      border: none;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 25px;
      @media screen and (max-width: 768px) {
        position: relative;
        height: 360px !important;
        border-radius: 0;
      }
      @media screen and (max-width: 550px) {
        height: 300px !important;
      }
      @media screen and (max-width: 500px) {
        height: 280px !important;
      }
      @media screen and (max-width: 350px) {
        height: 240px !important;
      }
    }
  }

  .play-video-image {
    min-width: 50px;
  }
  .video-overlay {
    bottom: 70px;
  }
  .play-pause-icon {
    &.show-overlay {
      top: 0;
      left: 0;
      right: 0;
      bottom: 40px;
      
      background-color: #00000066;
      border-radius: 30px;
      @media screen and (max-width: 768px) {
        // background-color: transparent;
        padding-top: 56%;
        border-radius: 0;
        bottom: auto;
        top: calc(50% - 30px);
        transform: translateY(-50%);
      }
      
      @media screen and (max-width: 550px) {
        top: calc(50% - 40px);
      }
      @media screen and (max-width: 450px) {
        top: calc(50% - 30px);
      }
      @media screen and (max-width: 400px) {
        top: calc(50% - 25px);
      }
      .play-video-buttons-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .volume-icon {
    bottom: 60px;
    left: 20px;
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    @media screen and (max-width: 768px) {
      right: 30px;
      left: auto;
      bottom: 0px;
    }
    
    @media screen and (max-width: 600px) {
      bottom: 30px;
    }
    .volume {
      width: 38px;
      height: 38px;
      background-size: contain;
    }
  }
  .vp-video-wrapper {
    border-radius: 25px;
    overflow: hidden;
  }
  .custom-arrow {
    right: 30px;
    bottom: 60px;
    z-index: 100;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #fff;
    color: #000;
    @media screen and (max-width: 768px) {
      background-color: #000;
      color: #fff;
      top: auto;
      bottom: 0px;
    }
    @media screen and (max-width: 600px) {
      bottom: 30px;
    }
    @media screen and (max-width: 768px) {
      &.arrow-prev {
        left: 30px;
      }
      &.arrow-next {
        left: 75px;
      }
    }
    &.arrow-prev {
      right: 80px;
    }
    i {
      font-size: 1.4rem;
    }
  }
  .p-carousel {
    max-width: 640px;
    .p-carousel-indicators {
      justify-content: flex-end;
      align-items: center;
      @media screen and (max-width: 768px) {
        justify-content: flex-start;
        position: absolute;
        bottom: 0px;
        left: 115px;
      }
      @media screen and (max-width: 600px) {
        bottom: 30px;
      }
      .p-carousel-indicator {
        margin-bottom: 0;
        button {
          width: 4px;
          height: 4px;
          background-color: #130c0e;
          opacity: 1;
          border: 0;
          box-shadow: none;
          border-radius: 50%;
          &:hover {
            opacity: 1;
          }
        }
        &.p-highlight button {
          width: 8px;
          height: 8px;
        }
      }
    }
  }
}

@import '../../../variables.scss';
.header-desktop-container {
  padding: 18px 10%;
  @media screen and (min-width: 1921px) {
    max-width: $max-width-container;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin: 0 auto !important ;
  }
  @media screen and (max-width: 1200px) {
    padding: 18px 5%;
  }
  @media screen and (max-width: 992px) {
    padding: 16px;
  }
  .lang.p-dropdown {
    .p-dropdown-label {
      &.p-placeholder {
        color: #000 !important;
        padding-right: 0;
        font-size: 14px;
      }
    }
  }
  .header-navigation-item {
    position: relative;
    font-size: 14px;
    &.text-green {
      color: #14884F;
    }
    .header-navigation-item-text {
      &.active {
        &::before {
          content: '';
          position: absolute;
          bottom: -37px;
          left: 0;
          right: 0;
          height: 4px;
          background-color: #00a664;
        }
      }
    }
  }
}
